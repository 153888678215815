@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@700&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap'); */

@font-face {
  font-family: GilroyBold;
  src: url(/static/media/gilroybold.0094146a.OTF);
}
@font-face {
  font-family: GilroyLight;
  src: url(/static/media/gilroylight.cc7ab55d.OTF);
}
@font-face {
  font-family: Grotesk;
  src: url(/static/media/grotesk.0b78553c.OTF);
}
@font-face {
  font-family: LogoFont;
  src: url(/static/media/logofont.925c16b3.OTF);
}

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

